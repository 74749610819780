<template>
  <div
    :data-testid="`relation_group_${group.id}`"
    class="Row columns border-t-grey-light"
  >
    <restricted-button
      :to="{ name:'connect.groups.view', params: { groupId: group.id } }"
      class="column"
      tag="a"
      permission="relations.group.view"
      data-testid="ViewGroup"
    >
      <div class="columns">
        <div
          class="column is-5"
          data-testid="groupName"
        >
          {{ group.name }}
        </div>

        <div class="column is-4 has-text-centered">
          {{ abbrMoney(group.stats.data.hypefactors_value) }}
        </div>

        <div class="column is-3 has-text-centered">
          {{ group.relations_count }}
        </div>
      </div>
    </restricted-button>

    <div class="column is-narrow">
      <el-dropdown trigger="click">
        <span class="button is-plain is-round is-dark has-background" data-testid="optionsTrigger">
          <span class="icon">
            <v-icon type="ellipsis-v" size="lg" />
          </span>
        </span>

        <el-dropdown-menu slot="dropdown" :data-testid="`Group-${group.id}__DropDown`">
          <el-dropdown-item>
            <restricted-button
              tag="a"
              permission="relations.group.view"
              :to="{ name: 'connect.groups.view', params: { groupId: group.id } }"
              data-testid="ViewGroupLink"
            >
              <v-icon type="eye" />
              {{ $t('components.relation_group_item.view_group') }}
            </restricted-button>
          </el-dropdown-item>

          <el-dropdown-item>
            <restricted-button
              tag="a"
              permission="relations.group.update"
              :to="{ name: 'connect.groups.edit', params: { groupId: group.id } }"
              data-testid="EditGroupLink"
            >
              <v-icon type="pencil" />
              {{ $t('components.relation_group_item.edit_group') }}
            </restricted-button>
          </el-dropdown-item>

          <el-dropdown-item>
            <restricted-button
              tag="a"
              permission="relations.group.delete"
              action-class="has-text-danger"
              data-testid="DeleteGroupLink"
              @click.prevent="deleteGroup"
            >
              <v-icon type="trash" />
              {{ $t('components.relation_group_item.delete_group') }}
            </restricted-button>
          </el-dropdown-item>

          <el-dropdown-item>
            <restricted-button
              :to="{ name: 'emails.new', query: { recipient } }"
              permission="email.create"
              tag="a"
              data-testid="ComposeEmailTrigger"
            >
              <v-icon type="envelope" />
              {{ $t('components.relation_group_item.compose_email') }}
            </restricted-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import FormatMoneyAbbrMixin from '@hypefactors/shared/js/mixins/FormatMoneyAbbrMixin'

export default {
  mixins: [FormatMoneyAbbrMixin],

  props: {
    group: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    recipient () {
      return JSON.stringify({
        relation_id: this.group.id,
        name: this.group.name
      })
    }
  },

  methods: {
    async deleteGroup () {
      try {
        await this.$confirm(this.$t('components.relation_group_item.confirm_remove_group'))

        this.isLoading = true

        const successMessage = this.$t('success.relation_group_removed')

        await this.$store.dispatch('connect/groups/delete', this.group)

        this.$notify.success(successMessage)

        this.$emit('deleted', this.group)
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
