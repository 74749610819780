<template>
  <page-section class="ConnectGroups" hide-title>
    <template #above-title>
      <dashboard-filters-v2 class="ConnectGroupFilter m-b-m">
        <template #primary>
          <search-input />
        </template>

        <template #secondary>
          <dashboard-filters-v2-item>
            <filter-sort-by />
          </dashboard-filters-v2-item>

          <div class="DashboardFiltersV2-ButtonsContainer">
            <dashboard-filters-v2-item>
              <restricted-button
                :to="{ name:'connect.groups.create' }"
                permission="relations.group.create"
                action-class="button is-dark"
                data-testid="CreateGroupButton"
              >
                {{ $t('components.relation_group_filter.create_group') }}
              </restricted-button>
            </dashboard-filters-v2-item>
          </div>
        </template>
      </dashboard-filters-v2>
    </template>

    <div class="relation-groups-list">
      <div class="Relations-group-item_header columns m-b-l">
        <div class="column">
          <div class="columns">
            <div class="column is-5 p-b-none">
              <div
                class="label is-uppercase"
                data-testid="groupHeaderName"
              >
                {{ $t('components.relation_group_filter.list_view_headers.name') }}
              </div>
            </div>

            <div class="column is-4 p-b-none has-text-centered">
              <div class="label is-uppercase">
                {{ $t('components.relation_group_filter.list_view_headers.score') }}
              </div>
            </div>

            <div class="column is-3 p-b-none has-text-centered">
              <div class="label is-uppercase">
                {{ $t('components.relation_group_filter.list_view_headers.relations') }}
              </div>
            </div>
          </div>
        </div>

        <div class="column is-narrow">
          <v-icon type="" />
        </div>
      </div>

      <load-more
        ref="groupsLoadMore"
        :endpoint="relationGroupsEndpoint"
        :placeholder-items="2"
        placeholder="RelationGroupPlaceholder"
      >
        <template #item="{ item: group, removeItem }">
          <index-group-row :group="group" @deleted="removeItem" />
        </template>
      </load-more>
    </div>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'

import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'
import SearchInput from '@/components/connect/_filters/SearchInput'
import FilterSortBy from '@/components/connect/_filters/SortBy'
import IndexGroupRow from '@/components/connect/groups/index/Row'

export default {
  components: {
    SearchInput,
    FilterSortBy,
    IndexGroupRow
  },

  computed: {
    ...mapGetters(['getFilter']),

    relationGroupsEndpoint () {
      return buildUrlWithParams('connect/groups', {
        brand: this.activeBrandId,
        include: ['stats'],
        search: this.getFilter('searchQuery'),
        sort: this.getFilter('sortBy')
      })
    }
  }
}
</script>
