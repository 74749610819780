<template>
  <form-field
    #default="{ validator, classes }"
    :validator="$v.search.query"
    name="query"
    class="SearchInput"
    show-single-error
    left-icon="search"
    icon-class="hf-lg"
  >
    <input
      v-model="validator.$model"
      :class="classes"
      :placeholder="$t('forms.search')"
      type="text"
      class="input is-size-4 is-transparent has-text-weight-bold"
      data-testid="ConnectSearch__Input"
      @input="sendQuery"
    >
  </form-field>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { minLength } from 'vuelidate/lib/validators'

import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'

/**
 * @module SearchInput
 */
export default {
  name: 'SearchInput',

  mixins: [debounceMixin(['sendQuery'])],

  props: {
    defaultSearchQuery: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      search: {
        query: null
      }
    }
  },

  validations: {
    search: {
      query: {
        minLength: minLength(3)
      }
    }
  },

  computed: {
    ...mapGetters('globalFilters', [
      'searchQuery'
    ])
  },

  mounted () {
    this.search.query = this.searchQuery || this.defaultSearchQuery

    this.setSearchQuery(this.search.query)
  },

  methods: {
    ...mapActions('globalFilters', [
      'setSearchQuery'
    ]),

    sendQuery () {
      if (this.$v.search.$error) {
        return
      }

      let searchQuery = this.search.query

      this.setSearchQuery(searchQuery)

      // TODO: Remove this later...
      this.$emit('update:query', searchQuery)
    }
  }
}
</script>
