<template>
  <dropdown-list
    :elements="choices"
    :selected-elements="sortBy"
    :handler="toggleSortBy"
    filter-by="name"
    label-key="name"
    value-key="id"
    is-active-prop="id"
    class="picker"
  >
    <template #trigger>
      <dashboard-filters-icon
        :label="$t('forms.sort_by')"
        class="DashboardFiltersV2-DropdownTrigger"
        icon="sort-amount-desc"
      />
    </template>

    <template slot-scope="{ element }">
      <span class="is-uppercase">
        {{ element.name }}
      </span>
    </template>
  </dropdown-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * @module DashboardFiltersSortBy
 */
export default {
  name: 'DashboardFiltersSortBy',

  props: {
    choices: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters('globalFilters', [
      'sortBy'
    ])
  },

  methods: {
    ...mapActions('globalFilters', [
      'toggleSortBy'
    ])
  }
}
</script>
