<template>
  <dashboard-filters-sort-by :choices="choices" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DashboardFiltersSortBy from '@hypefactors/shared/js/components/filters/DashboardFiltersSortBy.vue'

export default {
  components: {
    DashboardFiltersSortBy
  },

  props: {
    defaultSort: {
      type: String,
      required: false,
      default: () => 'a_z'
    }
  },

  data () {
    return {
      choices: [
        { id: 'a_z', name: this.$t('forms.sort_by_a_z') },
        { id: 'z_a', name: this.$t('forms.sort_by_z_a') },
        { id: 'newest', name: this.$t('forms.sort_by_newest') },
        { id: 'oldest', name: this.$t('forms.sort_by_oldest') }
      ]
    }
  },

  computed: {
    ...mapGetters('globalFilters', [
      'sortBy'
    ])
  },

  mounted () {
    if (this.sortBy && this.sortBy.length === 0) {
      this.toggleSortBy(this.defaultSort)
    }
  },

  methods: {
    ...mapActions('globalFilters', [
      'toggleSortBy'
    ])
  }
}
</script>
